<template>
  <div
    id="issue-details"
    class=""
  >
    <div class="p-2">
      <cp-issuance-head />
      <cp-tabs
        v-model="selectedTab"
        :tabs="tabs"
        class="mb-5"
      />
      <keep-alive>
        <component
          :is="selectedTab"
          :token-name="currentToken.name"
          :token-blockchain-network="currentToken.blockchainNetwork"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CpTabs from '~/components/common/standalone-components/cp-tabs';
import CpIssuanceControlBook from './components/control-book/index';
import CpIssuanceTransactions from './components/transactions/index';
import CpIssuanceHead from './components/head';


export default {
  name: 'IssueDetails',
  metaInfo: {
    title: 'Issue Details',
  },
  components: {
    CpTabs,
    CpIssuanceControlBook,
    CpIssuanceHead,
    CpIssuanceTransactions,
  },
  data() {
    return {
      tabs: [
        { text: this.$t('issueDetails.label.tab.controlBook'), value: 'cp-issuance-control-book' },
        { text: this.$t('issueDetails.label.tab.transactions'), value: 'cp-issuance-transactions' },
      ],
      selectedTab: 'cp-issuance-control-book',
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
  },
};
</script>
