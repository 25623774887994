<template>
  <div class="row">
    <cp-control-book-info-card>
      <li class="list-group-item">
        <span class="bold">{{ tokenName }} - {{ tokenBlockchainNetwork }}</span>
      </li>
      <li class="list-group-item">
        <span>{{ $t('issueDetails.token.securitiesAuthorized') }}</span>
        <span class="left-li">{{ new Intl.NumberFormat('en-US').format( data.authorizedSecurities) }}</span>
      </li>
      <li class="list-group-item">
        <span>{{ $t('issueDetails.token.securitiesOutstanding') }}</span>
        <span class="left-li">{{ new Intl.NumberFormat('en-US').format( data.issuedSecurities) }}</span>
      </li>
      <li class="list-group-item">
        <span>{{ $t('issueDetails.token.securitiesAvailableToIssue') }}</span>
        <span class="left-li">{{ new Intl.NumberFormat('en-US').format( data.authorizedSecurities - data.issuedSecurities) }}</span>
      </li>
    </cp-control-book-info-card>
    <cp-control-book-info-card>
      <li class="list-group-item">
        <span class="bold">{{ $t('issueDetails.holding.title') }}</span>
      </li>
      <li class="list-group-item">
        <span>{{ $t('issueDetails.holding.bookSecurities') }}</span>
        <span class="left-li">{{ new Intl.NumberFormat('en-US').format( data.totalIssuedToDRSTokens) }}</span>
      </li>
      <li class="list-group-item">
        <span>{{ $t('issueDetails.holding.digitalSecurities') }}</span>
        <span class="left-li">{{ new Intl.NumberFormat('en-US').format( data.totalIssuedToBlockchain) }}</span>
      </li>
      <li class="list-group-item refill" />
    </cp-control-book-info-card>
  </div>
</template>

<script>
import CpControlBookInfoCard from './control-book-info-card';

export default {
  name: 'CpControlBookInfo',
  components: {
    CpControlBookInfoCard,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    tokenName: {
      type: String,
      required: true,
    },
    tokenBlockchainNetwork: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  .left-li{
    position: absolute;
    right: 20px;
    font-weight: 400;
  }
  .refill{
    padding: 20px;
  }
  .bold {
    font-weight: 700;
    font-size: 1rem
  }
</style>
