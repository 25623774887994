<template>
  <div class="col-md-6">
    <div class="card">
      <ul class="list-group list-group-flush">
        <slot />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CpControlBookInfoCard',
};
</script>
