<template>
  <div>
    <cb-table
      :items="data"
      :fields="computedTableFields"
      :query-params="queryParams"
      :total-items="totalItems"
      :busy="tableLoading"
      @onPagination="$emit('onPagination', $event, 'activity')"
    >
      <template
        slot="closedTimestamp"
        slot-scope="{ rowData }"
      >
        {{ getDate(rowData.value) | dateAndTimeFilter }}
      </template>
      <template
        slot="ethTxId"
        slot-scope="{ rowData: { item } }"
      >
        <a
          v-if="item.ethTxId && item.txIdLink"
          :href="item.txIdLink"
          target="_blank"
          class="ellipsis"
        >
          {{ item.ethTxId }}
        </a>
        <span
          v-else-if="item.ethTxId"
          class="ellipsis"
        >
          {{ item.ethTxId }}
        </span>
        <hr
          v-else
          class="line"
        >
      </template>
      <template
        slot="sender.investorName"
        slot-scope="{ rowData: { item } }"
      >
        <span>
          {{ item.senderType === 'investor' ? item.sender.investorName : item.senderType }}
        </span>
      </template>
      <template
        slot="receiver.investorName"
        slot-scope="{ rowData: { item } }"
      >
        <span>
          {{ item.receiverType === 'investor' ? item.receiver.investorName : item.receiverType }}
        </span>
      </template>
      <template
        slot="type"
        slot-scope="{ rowData }"
      >
        {{ getTypeTranslation(rowData.value) }}
      </template>
      <template
        slot="amount"
        slot-scope="{ rowData }"
      >
        {{ formatQuantity(rowData.value) }}
      </template>
    </cb-table>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { tableColumns, transactionTypes } from '../options';
import CbTable from '../shared/cb-table';

export default {
  name: 'CpTokenTransactionsTable',
  components: {
    CbTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    queryParams: {
      type: Object,
      required: true,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableColumns,
    };
  },
  computed: {
    ...mapGetters('issuersInfo', ['issuerInfo']),
    computedTableFields() {
      const blockchainSign = this.$options.filters.blockchainSign(this.blockchainType);
      const tableField = this.tableColumns.tokenTransactions.find(field => field.key === 'ethTxId');
      tableField.label = this.$t('dtl.input.blockchainTxId', [blockchainSign]);
      return this.tableColumns.tokenTransactions;
    },
    blockchainType() {
      return this.issuerInfo.blockchain;
    },
  },
  methods: {
    getDate(date) {
      return moment(date).format('MMMM DD, YYYY hh:mm A');
    },
    getTypeTranslation(type) {
      return this.$t(transactionTypes[type]);
    },
    formatQuantity(val) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
      }).format(val.toFixed(2));
    },
  },
};
</script>

<style scoped>
  .ellipsis{
    display: block;
    white-space: nowrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto;
  }
  .line{
    width: 25px;
    height: 0px;
    margin: 9px auto 0 auto;
    border: 1px solid #848B93;
    transform: rotate(0deg);
  }
</style>
