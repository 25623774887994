<template>
  <div>
    <b-table
      hover
      striped
      show-empty
      class="card-table"
      :busy="busy"
      :items="items"
      :fields="getFields()"
      responsive
      @sort-changed="sortChange"
    >
      <slot
        v-for="key in slotsName"
        :slot="key"
        slot-scope="componentScope"
        :name="key"
        :row-data="componentScope"
      />
      <div
        slot="table-busy"
        class="text-center my-2"
      >
        <div
          class="row align-items-center mb-2"
        >
          <div class="col-md-12 text-center">
            <b-spinner class="align-middle" />
            &nbsp;{{ $t('component.cpTable.message.loading') }}
          </div>
        </div>
      </div>
    </b-table>
    <cp-pagination
      :total-items="totalItems"
      :per-page="queryParams.limit"
      :current-page="queryParams.page"
      @onChangePage="changeTablePage"
    />
  </div>
</template>

<script>
import { kebabCase } from 'change-case';
import { i18nKeyListConvert } from '~/utilities/i18n-util';
import CpPagination from '~/components/common/table/pagination';

export default {
  name: 'CbTable',
  components: {
    CpPagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    queryParams: {
      type: Object,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slotsName() {
      return Object.keys(this.$scopedSlots);
    },
  },
  methods: {
    getFields() {
      return i18nKeyListConvert(this.fields, 'label', this.i18nValues);
    },
    changeTablePage(page = this.queryParams.page) {
      this.queryParams.page = page - 1;
      this.$emit('onPagination', this.queryParams);
    },
    sortChange(data) {
      const orderField = data.sortBy || this.queryParams['order-field'];
      if (orderField) {
        this.queryParams['order-direction'] = !data.sortDesc ? 'asc' : 'desc';
        this.queryParams['order-field'] = kebabCase(orderField);
      } else {
        delete this.queryParams['order-direction'];
        delete this.queryParams['order-field'];
      }
      this.$emit('onPagination', this.queryParams);
    },
  },
};
</script>
