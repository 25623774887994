<template>
  <b-card
    header-class="font-weight-bold"
    body-class="p-0"
    :header="$t('issueDetails.table.title')"
    class="mt-5"
  >
    <cb-table
      :items="data"
      :fields="tableFields"
      :query-params="queryParams"
      :total-items="totalItems"
      :busy="tableLoading"
      @onPagination="$emit('onPagination', $event, 'activity')"
    >
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        {{ getDate(rowData.value) }}
      </template>

      <template
        slot="securitiesAuthorizedUpdate"
        slot-scope="{ rowData }"
      >
        <hr
          v-if="calcSecuritiesAuthorizedUpdate(rowData) === '0'"
          class="line"
        >
        <span v-else>
          {{ calcSecuritiesAuthorizedUpdate(rowData) }}
        </span>
      </template>
      <template
        slot="securitiesIssuedUpdate"
        slot-scope="{ rowData }"
      >
        <hr
          v-if="calcSecuritiesIssuedUpdate(rowData) === '0'"
          class="line"
        >
        <span v-else>{{ calcSecuritiesIssuedUpdate(rowData) }}</span>
      </template>
      <template
        slot="updateType"
        slot-scope="{ rowData }"
      >
        <hr
          v-if="calcUpdateType(rowData) === ''"
          class="line"
        >
        <span v-else>{{ calcUpdateType(rowData) }}</span>
      </template>
    </cb-table>
  </b-card>
</template>


<script>
import moment from 'moment';
import CbTable from '../shared/cb-table';
import { tableColumns } from '../options';

export default {
  name: 'CbControlBookRecords',
  components: {
    CbTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    queryParams: {
      type: Object,
      required: true,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableFields() {
      return tableColumns.controlBookRecords;
    },
  },
  methods: {
    calcSecuritiesAuthorizedUpdate(rowData) {
      return new Intl.NumberFormat('en-US').format(rowData.item.authorizedSecurities - rowData.item.previousAuthorizedSecurities);
    },
    calcSecuritiesIssuedUpdate(rowData) {
      return new Intl.NumberFormat('en-US').format(rowData.item.cbSecurities - rowData.item.previousCbSecurities);
    },
    calcUpdateType(rowData) {
      let type = '';
      const securitiesAuthorizedUpdate = rowData.item.authorizedSecurities - rowData.item.previousAuthorizedSecurities;
      const securitiesIssuedUpdate = rowData.item.cbSecurities - rowData.item.previousCbSecurities;
      if (securitiesAuthorizedUpdate !== 0 && securitiesIssuedUpdate !== 0) {
        type = this.$t('issueDetails.updateType.issuedAndAuthorized');
      } else if (securitiesAuthorizedUpdate !== 0) {
        type = this.$t('issueDetails.updateType.authorized');
      } else if (securitiesIssuedUpdate !== 0) {
        type = this.$t('issueDetails.updateType.issued');
      }
      return type;
    },
    getDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
  },
};
</script>

<style scoped>
  .font-weight-bold{
    font-size: 1rem;
  }
  .line{
    width: 25px;
    height: 0px;
    margin: 9px auto 0 auto;
    border: 1px solid #848B93;
    transform: rotate(0deg);
  }
</style>
