var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mt-5",attrs:{"header-class":"font-weight-bold","body-class":"p-0","header":_vm.$t('issueDetails.table.title')}},[_c('cb-table',{attrs:{"items":_vm.data,"fields":_vm.tableFields,"query-params":_vm.queryParams,"total-items":_vm.totalItems,"busy":_vm.tableLoading},on:{"onPagination":function($event){return _vm.$emit('onPagination', $event, 'activity')}},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.getDate(rowData.value))+" ")]}},{key:"securitiesAuthorizedUpdate",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.calcSecuritiesAuthorizedUpdate(rowData) === '0')?_c('hr',{staticClass:"line"}):_c('span',[_vm._v(" "+_vm._s(_vm.calcSecuritiesAuthorizedUpdate(rowData))+" ")])]}},{key:"securitiesIssuedUpdate",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.calcSecuritiesIssuedUpdate(rowData) === '0')?_c('hr',{staticClass:"line"}):_c('span',[_vm._v(_vm._s(_vm.calcSecuritiesIssuedUpdate(rowData)))])]}},{key:"updateType",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.calcUpdateType(rowData) === '')?_c('hr',{staticClass:"line"}):_c('span',[_vm._v(_vm._s(_vm.calcUpdateType(rowData)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }