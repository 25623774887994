<template>
  <div>
    <div class="header-block mb-4">
      <b-row>
        <b-col>
          <h4 class="font-weight-bold mb-0">
            <i class="ion ion-md-apps" />
            {{ $t('issueDetails.prependTitle') }}
            <span class="text-muted font-weight-normal">
              |  {{ $t('issueDetails.title') }}
            </span>
          </h4>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import EmailIcon from '~/assets/img/Email.svg';

export default {
  name: 'CpIssuanceHead',
  data() {
    return {
      EmailIcon,
    };
  },
};
</script>
