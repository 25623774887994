var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cb-table',{attrs:{"items":_vm.data,"fields":_vm.computedTableFields,"query-params":_vm.queryParams,"total-items":_vm.totalItems,"busy":_vm.tableLoading},on:{"onPagination":function($event){return _vm.$emit('onPagination', $event, 'activity')}},scopedSlots:_vm._u([{key:"closedTimestamp",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateAndTimeFilter")(_vm.getDate(rowData.value)))+" ")]}},{key:"ethTxId",fn:function(ref){
var item = ref.rowData.item;
return [(item.ethTxId && item.txIdLink)?_c('a',{staticClass:"ellipsis",attrs:{"href":item.txIdLink,"target":"_blank"}},[_vm._v(" "+_vm._s(item.ethTxId)+" ")]):(item.ethTxId)?_c('span',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(item.ethTxId)+" ")]):_c('hr',{staticClass:"line"})]}},{key:"sender.investorName",fn:function(ref){
var item = ref.rowData.item;
return [_c('span',[_vm._v(" "+_vm._s(item.senderType === 'investor' ? item.sender.investorName : item.senderType)+" ")])]}},{key:"receiver.investorName",fn:function(ref){
var item = ref.rowData.item;
return [_c('span',[_vm._v(" "+_vm._s(item.receiverType === 'investor' ? item.receiver.investorName : item.receiverType)+" ")])]}},{key:"type",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.getTypeTranslation(rowData.value))+" ")]}},{key:"amount",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.formatQuantity(rowData.value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }