<script src="../../lang/en.js"></script>
<template>
  <div class="row">
    <div
      v-if="!isReady"
      class="col-md-12 align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('issueDetails.loading') }}
      </div>
    </div>
    <div
      v-if="isReady"
      class="col-md-12"
    >
      <cp-control-book-info
        :data="getControlBookInfoData"
        :token-name="displayTokenName"
        :token-blockchain-network="displayTokenBlockchainNetwork"
      />
    </div>
    <div
      v-if="isReady"
      class="col-md-12 mt-4"
    >
      <cb-control-book-records
        :data="controlBookRecordsData"
        :query-params="queryParams"
        :total-items="controlBookRecordsTotal"
        :table-loading="isBusy"
        @onPagination="setQueryParams"
      />
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie';
import { mapActions, mapGetters } from 'vuex';
import CpControlBookInfo from './control-book-info';
import CbControlBookRecords from './control-book-records';
import { BLOCKCHAIN_NETWORK_MAPPER } from '../options';

export default {
  name: 'CpIssuanceControlBook',
  components: {
    CpControlBookInfo,
    CbControlBookRecords,
  },
  props: {
    tokenName: {
      type: String,
      required: true,
    },
    tokenBlockchainNetwork: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      displayTokenName: '',
      displayTokenBlockchainNetwork: '',
      isReady: false,
      isBusy: true,
      queryParams: {
        page: 0,
        limit: parseInt(Cookie.get('perPage')) || 10,
        'order-direction': 'desc',
      },
    };
  },
  computed: {
    ...mapGetters('issueDetails', ['getControlBookRecordsTotal', 'getControlBookRecordsData', 'getControlBookInfoData']),
    controlBookInfoData() {
      return this.getControlBookInfoData;
    },
    controlBookRecordsData() {
      return this.getControlBookRecordsData();
    },
    controlBookRecordsTotal() {
      return this.getControlBookRecordsTotal;
    },
  },
  watch: {
    $route() {
      this.setNotReady();
      this.getAllData();
      this.displayTokenName = this.tokenName;
      this.displayTokenBlockchainNetwork = BLOCKCHAIN_NETWORK_MAPPER[this.tokenBlockchainNetwork];
    },
  },
  beforeMount() {
    this.displayTokenName = this.tokenName;
    this.displayTokenBlockchainNetwork = BLOCKCHAIN_NETWORK_MAPPER[this.tokenBlockchainNetwork];
    this.getAllData();
  },
  methods: {
    ...mapActions('issueDetails', ['controlBookRecords', 'controlBookInfo']),
    getControlBookRecords() {
      return this.controlBookRecords({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, queryParams: this.queryParams })
        .then(() => this.disableLoad());
    },
    getAllData() {
      Promise.allSettled([this.getControlBookRecords(), this.getControlBookInfo()])
        .then(() => this.setReady());
    },
    getControlBookInfo() {
      return this.controlBookInfo({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId });
    },
    setReady() {
      this.isReady = true;
    },
    setNotReady() {
      this.isReady = false;
    },
    disableLoad() {
      this.isBusy = false;
    },
    enableLoad() {
      this.isBusy = true;
    },
    setQueryParams(queryParams) {
      this.enableLoad();
      this.queryParams = queryParams;
      this.getControlBookRecords();
    },
  },
};
</script>
